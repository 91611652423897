const moment = require('moment');
import RfmEventFilterComponent from '@/components/chartFilters/rfmEventFilter/rfmEventFilter';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import FiltersMixin from './../../mixins/filtersMixins';
import rfmModule from '@/services/rfm';
import segmentModule from '@/services/sessionFilter';
import RfmTooltip from './rfmTooltip';

export default {
  name: 'home',
  mixins: [FiltersMixin],

  components: {
    UserPropertyFilterComponent,
    RfmEventFilterComponent,
    RfmTooltip
  },
  data() {
    return {
      fetchingData: false,
      data: null,
      showMonetary: false,
      totalUsers: null,
      debugRFM: false,

      eventName: null,
      eventFilters: null,
      userFilters: null,

      // Timing properties
      startTime: null,
      endTime: null,
      timingFilter: '129600',
      durationType: 'latest',
      dateFilterValues: [],

      // Create segment popup variables
      createSegmentDialogVisible: false,
      creatingSegment: false,
      segmentName: '',
      userFilterForSegment: null
    };
  },
  watch: {
    timingFilter: {
      handler: function () {
        if (this.timingFilter) {
          this.fetchData();
        }
      }
    }
  },
  methods: {
    drawGraph(data) {
      this.data = data.rfm;
      this.totalUsers = parseInt(data.total);
    },

    async fetchData() {
      this.fetchingData = true;
      try {
        // Validate RFM filters
        if (!this.$refs.rfmEventFilter.validate()) {
          this.fetchingData = false;
          return;
        }

        // Parse start time, end time
        if (this.timingFilter === '') {
          this.startTime = moment(this.dateFilterValues[0]);
          this.endTime = moment(this.dateFilterValues[1]);
        } else {
          this.startTime = moment().subtract(this.timingFilter, 'minutes');
          this.endTime = moment();
        }

        // Min 1 month time period is required.
        if (this.endTime.diff(this.startTime, 'days') < 30) {
          this.warningToast('Please select at least 30 days.');
          this.fetchingData = false;
          return;
        }

        // Read RFM Params
        let rfmParams = this.$refs.rfmEventFilter.getFilters();
        this.eventName = rfmParams.event.eventName;
        this.eventFilters = rfmParams.event.filters;
        this.showMonetary = rfmParams.monetary !== undefined;

        // Read Segment params
        this.userFilters = this.$refs.userFilterComponent.getFilters();
        rfmParams.userFilters = this.userFilters;

        rfmParams.startTime = this.startTime.format('YYYY-MM-DD HH:mm:ss');
        rfmParams.endTime = this.endTime.format('YYYY-MM-DD HH:mm:ss');
        console.log('Params', rfmParams);

        // showMonetary
        let result = await rfmModule.rfmQuery(rfmParams);
        this.eventPerformed = rfmParams.event.eventName;
        // console.log('data returned are', result.data.rfm.hibernating.segmentQuery.performedFilter[0].duration);
        this.drawGraph(result.data);

        this.fetchingData = false;
      } catch (err) {
        this.fetchingData = false;
        console.error(err);
        this.errorToast('Something went wrong');
      }
    },

    async showCreateCampaignPopup(bucket) {
      // Show popup
      this.createSegmentDialogVisible = true;
      this.segmentName = 'RFM - ' + bucket.title;
      this.userFilterForSegment = bucket.segmentQuery;
    },

    async createSegment() {
      // Validate Segment Name
      if (!this.segmentName) {
        this.errorToast('Please provide segment name.');
        return;
      }

      // Call save segment api.
      this.creatingSegment = true;
      try {
        let params = {
          filterName: this.segmentName,
          filterData: this.userFilterForSegment
        };
        let result = await segmentModule.createSegment(params, this);
        this.segmentId = result.data.id;
        this.successToast('Segment Created.');

        let routeData = this.$router.resolve({
          name: 'CreateSegment',
          params: { id: this.segmentId }
        });
        window.open(routeData.href, '_blank');

        this.creatingSegment = false;
        this.createSegmentDialogVisible = false;
      } catch (err) {
        console.log(err);
        this.errorToast('Something went wrong.');
        this.creatingSegment = false;
      }
    }
  },
  mounted() {
    this.dateFilterValues = [moment().subtract(1, 'month').valueOf(), moment()];
  }
};
